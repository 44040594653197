import Typography from "@mui/material/Typography"
import { useSelector } from "react-redux"

const HomeContentDesktop = () => {

    const { template_page_content } = useSelector((state) => state.globalReducer);
    const page_content = template_page_content['home'];

    return (
            
            (page_content.section_3 || page_content.section_4) && (<div 
            className="mt-4 mb-4 pt-2 pb-2"
            style={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                fontSize: 12,
                // backgroundColor: '#e5dfcf',
                // color: '#a68b70',
                color: '#616467'
                
            }}>
                {page_content.section_3 && <div className="mt-2 mb-4" style={{paddingLeft: 30}}>
                    <Typography className="f-size_3 uppercase" component="h2">{page_content.section_3.title}</Typography>
                    <Typography className="f-size_2 mt-2">
                        {page_content.section_3.content}
                    </Typography>
                </div>}

                {page_content.section_4 && <div className="mt-2 mb-4" style={{marginLeft: 40, paddingRight: 30}}>
                    <Typography className="f-size_3 uppercase" component="h2">{page_content.section_4.title}</Typography>
                    <Typography className="f-size_2 mt-2">
                        {page_content.section_4.content}
                    </Typography>
                </div>}
        </div>)
    )
}

export default HomeContentDesktop